import { moduleActionsType } from '../actions';

export function modules (state = {}, action) {
  switch (action.type) {
    case moduleActionsType.LIST_MODULES:
      return {
        list: action.module
      };

    case moduleActionsType.LIST_MODULES_PROGRESS:
      return {
        list: action.moduleProgress
      };

    case moduleActionsType.SUCCESS_CERTIFICATE_GENERATION:
      return state
  
    default:
      return state
  }
}

