import { studentActionsType } from '../actions';

export function students (state = {}, action) {
  let list;
  switch (action.type) {
    case studentActionsType.ADD_STUDENT:
      list = state.list;
      list.push(action.student)
      return {
        list: list
      };

    case studentActionsType.UPDATE_STUDENT:
      list = state.list;
      list.map(cl => {
          if (cl.studentId === action.student.studentId) cl.studentName = action.student.studentName
      });
      return {
        list: list
      };         

    case studentActionsType.MOVE_STUDENT:
      return {
        moved: action.moved
      }; 

    case studentActionsType.DELETE_STUDENT:
      list = state.list;
      list = list.filter(st => st.studentId !== action.studentId);
      return {
        list: list
      };

    case studentActionsType.LIST_STUDENTS:
      return {
        list: action.students
      };

    default:
      return state
  }
}

