import { commonService } from './';
import { AppStorage } from '../utils';
import * as constants from '../constants';

//
function getAllStudents(class_id) {
    return commonService.get('Student/getlistbyclassid/' + class_id);
}

//
function deleteStudent(id) {
    return commonService.del('Student/delete/' + id);
}

//
function addStudent(studentName, class_id) {
    const school = AppStorage.get(constants.SELECTED_SCHOOL);
    const body = {
        "SchoolId": school.schoolId,
        "ClassId": class_id,
        "StudentName": studentName
    };
    return commonService.post('Student/insert', body);
}

//
function updateStudent(student_id, student_name) {
    const school = AppStorage.get(constants.SELECTED_SCHOOL);
    const body = {
        "SchoolId": school.schoolId,
        "StudentId": student_id,
        "StudentName": student_name
    };
    return commonService.post('Student/updatename', body);
}

//
function moveStudent(student_id, newclass_id) {
    const body = {
        "StudentId": student_id,
        "ClassId": newclass_id
    };
    return commonService.post('Student/updateclass', body);
}

//
function updateStudentProgress(body) {
    return commonService.post('Student/updatecompletedmodule', body);
}

//
export const studentService = {
    getAllStudents,
    deleteStudent,
    addStudent,
    updateStudent,
    updateStudentProgress,
    moveStudent
};
