// Sessions variable's name
export const CURRENT_LANG = "current_lang";
export const CURRENT_USER = "current_user";
export const CURRENT_ROLE = "current_user_role";
export const TOKEN_USER = "token_user";

// Session game's variables
export const IS_CALLING_GAME = "is_calling_game";
export const RCSTORED = "Rfcstored";
export const SELECTED_SCHOOL = "selectedSchool";
export const SELECTED_CLASS = "selectedClass";
export const SELECTED_STUDENT = "selectedStudent";
export const SELECTED_ADVENTURE_TYPE = "selectedAdventureType";
export const SELECTED_MODULE = "selectedModule";
export const SELECTED_ADVENTURE_LINK = "selectedAdventureLink";
export const SELECTED_ACTIVITY = "selectedActivity";
// End game's variables

// Langues
export const LNG_FR = "fr";
export const LNG_EN = "en";

// Role
export const ADMIN = "Admin";
export const STUDENT = "Student";

// Adventure
export const RFC_ADVENTURE = 1;
export const SRT_ADVENTURE = 2;
export const SRT_ADVENTURE_LEVEL1 = "L1";
export const SRT_ADVENTURE_LEVEL2 = "L2";

// State of activity or module
export const DONE = "done";
export const DONEXT = "donext";

// left pages
export const RESCUELEFTPAGE = "rescuepageleft";
export const RESCUESELECTEDLEVEL = "rescuelevelselected";
export const LEFTCLASSPAGINATION = "leftclasspagination";
export const LEFTSTUDENTPAGINATION = "leftstudentpagination";

// type game
export const STORY = "Story";
export const GAME = "Game";

// Id of end activity
export const ENDACTIVITY_ID = 35;