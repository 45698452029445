import { authActionsType } from '../actions';

export function authentication(state = {}, action) {
  switch (action.type) {
    case authActionsType.LOGIN_REQUEST:
      return {
        email: action.email
      };
      
    case authActionsType.LOGIN_SUCCESS:
      return {
        user: action.user
      };

    case authActionsType.LOGIN_FAILURE:
      return {
        error: action.error
      };

    case authActionsType.LOGOUT:
      return {};

    default:
      return state
  }
}

