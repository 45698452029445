import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Helmet} from 'react-helmet'
import moment from 'moment';
import 'moment/locale/fr';

// styles for rainforest
import "./theme/css/bootstrap.min.css";
import "./theme/css/all.css";
import "./theme/scss/rainforest-ui.scss";
import AppRouter from './routers/Router';
import * as constants from "./constants";
import { AppStorage } from "./utils";

class App extends Component {
  constructor(props){
    super(props);
    let lng = AppStorage.get(constants.CURRENT_LANG)?AppStorage.get(constants.CURRENT_LANG):process.env.REACT_APP_DEFAULTLANGUAGE;
    AppStorage.set(constants.CURRENT_LANG, lng);
    this.props.i18n.changeLanguage(lng);
    
    //
    moment.locale(lng);
  }
  // Iit language to EN
  render() {
    const {t} = this.props;
    return (
      <>
        <Helmet>
          <title>{t('site.meta')}</title>
          <meta name="description" content={t('site.about.description')} />
        </Helmet>

        <AppRouter/>
      </>
    );
  }
}

export default withTranslation()(App);
