import { authActionsType } from './';
import { AppStorage } from '../../utils';
import { userService } from '../../services';
import { alertActions } from './';
import * as constants from '../../constants';
import { history } from "../../utils";

function login(username, password) {
    return dispatch => {
        userService.login(username, password)
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                });
            };
            
    function success(user) {
        AppStorage.set(constants.TOKEN_USER, user.token);
        AppStorage.set(constants.SELECTED_SCHOOL, user.userDetails);
        AppStorage.set(constants.CURRENT_ROLE, user.userDetails.role);

        if(user.userDetails.role === constants.ADMIN){
            history.push("/admin-page");
        }else if (user.userDetails.role === constants.STUDENT){
            history.push("/students-page");
        }else{
            history.push("/not-authorized");
        }
        return { type: authActionsType.LOGIN_SUCCESS, user }
    }
    function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

function logout(){
    return dispatch => {
        let lng = AppStorage.get(constants.CURRENT_LANG);
        AppStorage.destroy();
        AppStorage.set(constants.CURRENT_LANG, lng)
            .then(() => {
                    dispatch(signout());
                }
            )
    }
    function signout() { 
        return { type: authActionsType.LOGOUT } 
    }
}

export const authActions = {
    login,
    logout
};