import { commonService } from './';

//
function login(username, password) {
    const body = {
        "Login": username,
        "Password": password
    };
    return commonService.post('Authenticate/authenticate', body);
}

//
export const userService = {
    login
};
