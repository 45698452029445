import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { loading } from './loader.reducer';
import { authentication } from './auth.reducer';
import { classes } from './class.reducer';
import { students } from './student.reducer';
import { activities } from './activity.reducer';
import { modules } from './module.reducer';

const rootReducer = combineReducers({
  alert,
  loading,
  authentication,
  classes,
  students,
  activities,
  modules
});

export default rootReducer;