import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import RootReducer from '../reducers';
import { createLogger } from 'redux-logger';

// export default createStore(RootReducer, {}, applyMiddleware(thunk));

const loggerMiddleware = createLogger();

export const store = createStore(
    RootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);


export function getStore() {
    return store;
}