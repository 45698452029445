import { activityActionsType } from './';
import { activityService, studentService } from '../../services';
import { alertActions } from './';
import { history, tools } from "../../utils";
import * as constants from '../../constants';
import AppStorage from '../../utils/AppStorage';
import download from 'downloadjs'

//
function getStudentReport(student_id) {
    return dispatch => {
        activityService.getAllActivitiesByStudentGoupedByModule(student_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(report) { return { type: activityActionsType.LIST_ACTIVITIES_REPORTING, report } }
}

//
function getActivitiesByModule(module_id, student_id) {
    return dispatch => {
        activityService.getActivitiesWithstateByModuleAndStudent(module_id, student_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(activities) { return { type: activityActionsType.LIST_ACTIVITIES_BY_MODULE, activities } }
}

//
function getActivityById(activiity_id) {
    return dispatch => {
        activityService.getActivityById(activiity_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(activity) { return { type: activityActionsType.GET_ACTIVITY, activity } }
}

//
function updateAndGotoNext(isStop) {
    const rfcVariables = tools.getGameVariables();
    return dispatch => {
      const student = AppStorage.get(constants.SELECTED_STUDENT);
      let activitiesCompleted = JSON.parse(student?.activitiesCompleted) || [];
      let alreadyCompleted = activitiesCompleted.find(ac => ac.Activity_ID === parseInt(rfcVariables.currentActivityId))?true:false;
      if(!alreadyCompleted){
        let newCompleted = {
            "Time": rfcVariables.timeDate,
            "Activity_ID": rfcVariables.currentActivityId
        }
        activitiesCompleted.push(newCompleted);
        activitiesCompleted = JSON.stringify(activitiesCompleted);
        //
        const data = {
            "StudentId": rfcVariables.studentId,
            "CurrentActivityId": rfcVariables.currentActivityId,
            "NextActivityId": rfcVariables.nextActivityId,
            "StartingPoint": rfcVariables.startingPoint,
            "ActivitiesCompleted": activitiesCompleted
        };
        studentService.updateStudentProgress(data)
          .then(
              result => { 
                  let student = result.student;
                  let activity = result.activity;
                  AppStorage.set(constants.SELECTED_STUDENT, student);
                  if(activity.activityType == 'Story'){
                      gotoNextActivity(rfcVariables.nextActivityId, isStop);
                  }else{
                      student.next = rfcVariables.nextActivityId;
                      student.completed = true;
                      student.newly = true;
                      student.activity = activity;
                      dispatch(setDoneActivity(student));
                  }
              },
              error => {
                  history.push('/students-activity');
              });
      }else{
        activityService.getActivityById(rfcVariables.currentActivityId)
        .then(
            activity => { 
                if(activity.activityType == 'Story'){
                    gotoNextActivity(rfcVariables.nextActivityId, isStop);
                }else{
                    student.next = rfcVariables.nextActivityId;
                    student.completed = true;
                    student.newly = false;
                    student.activity = activity;
                    dispatch(setDoneActivity(student));
                }
            },
            error => {
                history.push('/students-activity');
            }
        ); 
      }
    }
}

function gotoNextActivity(idNextActivity, isStop) {
    activityService.getActivityById(idNextActivity)
      .then(
          nextActivity => { 
              if(!isStop){
                  tools.callGame(nextActivity.activityUrl, constants.RFC_ADVENTURE, nextActivity.activityId);
              }else{
                  history.push('/students-activity');
              }
          },
          error => {
            history.push('/students-activity');
          });    
    return { type: activityActionsType.SUCCESS_NEXT_GAME }
}
      
function setDoneActivity(activitydone = null) {
    return { type: activityActionsType.ACTIVITIY_DONE, activitydone };
}

function generateReport(student_id, student_name) {
    return dispatch => {
      activityService.generateReport(student_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(report) {
        download(report, `${student_name}-report.pdf`, "application/pdf");
        return { type: activityActionsType.SUCCESS_REPORT_GENERATION }
    }
}

//
export const activityActions = {
    getStudentReport,
    getActivitiesByModule,
    getActivityById,
    updateAndGotoNext,
    generateReport,
    setDoneActivity,
    gotoNextActivity
};
