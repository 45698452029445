import { moduleActionsType } from './';
import { moduleService } from '../../services';
import { alertActions } from './';
import download from 'downloadjs'

//
function getModules() {
    return dispatch => {
        moduleService.getAllModules()
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(module) { return { type: moduleActionsType.LIST_MODULES, module } }
}

//
function getProgressModules(student_id) {
    return dispatch => {
        moduleService.getModuleProgressByStudent(student_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(moduleProgress) { return { type: moduleActionsType.LIST_MODULES_PROGRESS, moduleProgress } }
}

//
function generateCertificate(module_id, student_id, student_name) {
    return dispatch => {
      moduleService.generateCertificate(module_id, student_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(report) {
        download(report, `${student_name}-${module_id}-certificate.pdf`, "application/pdf");
        return { type: moduleActionsType.SUCCESS_CERTIFICATE_GENERATION }
    }
}

export const moduleActions = {
    getModules,
    getProgressModules,
    generateCertificate
};
