
// Persistence while closed
// input : string [, object]
// output : json
import * as constants from "../constants"; 

class AppStorage {

    static get(k) {
        try {
            return JSON.parse(sessionStorage.getItem(k));
        }
        catch(e) {
            return null;
        }
    }
    
    static async set(k, v) {
        await sessionStorage.setItem(k, JSON.stringify(v));
    }

    static destroy(key = null) {
        if(key){
            sessionStorage.removeItem(key);
        }else{
            sessionStorage.clear();
        }
    }

    static isConnected() {
        return AppStorage.get(constants.TOKEN_USER) !== null;
    }
}

export default AppStorage;