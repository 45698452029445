
import SimpleReactValidator from 'simple-react-validator';
import * as constants from '../constants';
import { AppStorage } from '../utils';

export function InitValidator(t){
    return new SimpleReactValidator({
        locale: AppStorage.get(constants.CURRENT_LANG),
        messages: {
            required: t('error.required')
        }
    }); 
}
