import * as constants from '../constants';
import AppStorage from './AppStorage';

// Games url
const getGamesUrl = () => {
    return process.env.REACT_APP_SITEURL + '/';
};

// Redirect to url's game
function callGame(uri, adventure = constants.RFC_ADVENTURE, activityId = null) {
    AppStorage.set(constants.IS_CALLING_GAME, 1);
    setGameVariables(adventure, activityId);

    //window.location.href = process.env.REACT_APP_MODETEST==="true"?getGamesUrl() + "codingAdventures/introTwo22/introTwo.html":getGamesUrl() + uri
    window.location.href = process.env.REACT_APP_MODETEST==="true"? "./codingAdventures/introTwo22/introTwo.html": "./" + uri
}

// Control game's actions
const hasToUpdate = () => {
    let RfcStored = AppStorage.get(constants.RCSTORED);
    if(RfcStored && AppStorage.get(constants.IS_CALLING_GAME) === 1){
        AppStorage.set(constants.IS_CALLING_GAME, 0);
        console.log("Do update with ", RfcStored);
        return true;
    }
    return false;
};

// To call before jumping to game
const setGameVariables = (adventure, activityId) => {
    let sv = [];
    let oStudent = AppStorage.get(constants.SELECTED_STUDENT);

    sv[0] = true;

    if(adventure === constants.RFC_ADVENTURE){
        sv[1] = oStudent? oStudent.robotAvatarId:1;
        sv[2] = oStudent? oStudent.studentId:1;
        sv[3] = oStudent? oStudent.studentName:'';
        sv[4] = oStudent? oStudent.startingPoint:0;
        // Return to RFC
        sv[5] = "../../#/students-activity";
        sv[6] = activityId == constants.ENDACTIVITY_ID? "../../#/complete/end":"../../#/complete";
    }else if(adventure === constants.SRT_ADVENTURE_LEVEL1){
        // Return to STR
        sv[1] = "";
        sv[2] = "";
        sv[3] = "";
        sv[4] = "";
        sv[5] = "../../#/super-rescue-level1";
        sv[6] = "../../#/super-rescue-level1";        
    }else if(adventure === constants.SRT_ADVENTURE_LEVEL2){
        // Return to STR
        sv[1] = "";
        sv[2] = "";
        sv[3] = "";
        sv[4] = "";
        sv[5] = "../../#/super-rescue-level2";
        sv[6] = "../../#/super-rescue-level2";        
    }

    //
    let stringToStore = "";
    for(let loop = 0; loop < sv.length; loop++) {
        console.log("sv[loop] "+ sv[loop]);
        stringToStore = stringToStore + sv[loop].toString() + "|";
    }
    console.log("stringToPass " + stringToStore);
    sessionStorage.setItem(constants.RCSTORED, stringToStore);
};

// To call after game, before accessing to app
const getGameVariables = () => {
    let dataFromLocal = sessionStorage.getItem(constants.RCSTORED);
    console.log("Loaded from the sessionStore RCstored " + dataFromLocal)
    let data = {};
    if(dataFromLocal !== null) {
        const varArray = dataFromLocal.split("|");
        let studentId               = varArray[0] * 1;      
        let currentActivityId       = varArray[1] * 1;
        let nextActivityId          = varArray[2] * 1;
        let startingPoint           = varArray[3] * 1;
        let timeDate                = varArray[4];

        data = {
            'studentId': studentId,
            'currentActivityId': currentActivityId,
            'nextActivityId': nextActivityId,
            'startingPoint': startingPoint,
            'timeDate': timeDate    
        };
    }
    return data;
}

// Chunk array
function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
}

export default {
    getGamesUrl,
    callGame,
    hasToUpdate,
    setGameVariables,
    getGameVariables,
    chunkArray
}