import React from 'react';
import { Trans } from 'react-i18next';

const PageSpinner = ({ color = '#537780' }) => {
  return (
    <div className="spinner">
      <div className="content-spinner">
        <div>
          <img
            alt="..."
            className="img-fluid"
            src={require("../../theme/images/players/r7.png")}
          ></img>
        </div>
        <div className="spinner-text"><Trans>loading.content</Trans></div>
      </div>
    </div>
  );
};

export default PageSpinner;
