import AppStorage from './AppStorage';
import i18n from './i18n';
import history from './history';
import tools from './tools';
import { InitValidator } from './validators';
import { cancellablePromise } from "./cancellable-promise";

const noop = () => {};
const delay = n => new Promise(resolve => setTimeout(resolve, n));

export {
    AppStorage,
    i18n,
    history,
    tools,
    InitValidator,
    cancellablePromise,
    noop,
    delay
}