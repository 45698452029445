import axios from "axios";
import * as constants from "../constants"; 
import { loaderActionsType, alertActionsType } from '../redux/actions';
import { store } from '../redux/store';
import { AppStorage } from "../utils";

const APIURL = process.env.REACT_APP_URLAPI + "/api";

// Request interceptor
axios.interceptors.request.use((config) => {
    store.dispatch({ type: loaderActionsType.PENDING });
    store.dispatch({ type: alertActionsType.CLEAR });
    config.headers = getHeaders();
    return config;
}, error => {
    store.dispatch({ type: loaderActionsType.END });    
    return Promise.reject(error);
});

// Response interceptor
axios.interceptors.response.use(response => {
    //
    store.dispatch({ type: loaderActionsType.END });
    return response.data;
  }, error => {
    store.dispatch({ type: loaderActionsType.END });
    if(error.response){
        let error_message = "";
        if(typeof(error.response.data) === 'string') {
            error_message = error.response.data;
        }else {
            const tmp = error.response.data;
            error_message = tmp.title || tmp.toString();
        }
        return Promise.reject(error_message);
    }else{
        return Promise.reject(error.toString());
    }
});

//
function get(endpoint, filter = null){
    const url = `${APIURL}/` + endpoint;
    return axios.get(url);
}

function post(endpoint, data){
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, data);
}

//
function put(endpoint, data){

}

//
function del(endpoint){
    const url = `${APIURL}/` + endpoint;
    return axios.delete(url);
}

function all(endpoints) {

}

//
function getFile(endpoint) {
    const method = 'GET';
    const url = `${APIURL}/` + endpoint;
    return axios
        .request({
            url,
            method,
            responseType: 'blob'
        })
}

// Headers
function getHeaders (action = null) {
    let token = AppStorage.get(constants.TOKEN_USER);
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': token?'Bearer ' + token : 'No Auth',
        'headers': 'application/json'
    }
    return headers;
}

export const commonService = {
    get,
    post,
    put,
    del,
    all,
    getFile
};