import { activityActionsType } from '../actions';

export function activities (state = {}, action) {
  switch (action.type) {
    case activityActionsType.LIST_ACTIVITIES_REPORTING:
      return {
        report: action.report
      };

    case activityActionsType.LIST_ACTIVITIES_BY_MODULE:
      return {
        activitiesOfModule: action.activities
      };

    case activityActionsType.GET_ACTIVITY:
      return {
        activity: action.activity
      };

    case activityActionsType.SUCCESS_NEXT_GAME:
      return {
        success: true 
      };

    case activityActionsType.ACTIVITIY_DONE:
      return {
        activitydone: action.activitydone
      };

    case activityActionsType.SUCCESS_REPORT_GENERATION:
      return state

    default:
      return state
  }
}

