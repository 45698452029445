import { commonService } from './';
import { AppStorage } from '../utils';
import * as constants from '../constants';

//
function getAllClasses() {
    return commonService.get('Class/getall');
}

//
function getClassesBySchoolId(id) {
    return commonService.get('Class/getlistbyschoolid/' + id);
}

//
function deleteClass(id) {
    return commonService.del('Class/delete/' + id);
}

//
function updateClass(classId, className) {
    const school = AppStorage.get(constants.SELECTED_SCHOOL);
    const body = {
        "SchoolId": school.schoolId,
        "ClassId": classId,
        "ClassName": className
    };
    return commonService.post('Class/update', body);
}

//
function addClass(className) {
    const school = AppStorage.get(constants.SELECTED_SCHOOL);
    const body = {
        "SchoolId": school.schoolId,
        "ClassName": className
    };
    return commonService.post('Class/insert', body);
}

//
export const classService = {
    getAllClasses,
    getClassesBySchoolId,
    addClass,
    deleteClass,
    updateClass
};
