import { commonService } from './';

//
function getAllAcivities() {
    return commonService.get('Activity/getall');
}

//
function getActivityById(activity_id) {
    return commonService.get('Activity/getbyid/' + activity_id);
}

//
function getActivitySkill(activity_id) {
    return commonService.get('Activity/getactivitySkill/' + activity_id);
}

//
function getActivitiesByAdventure(adventure_type) {
    return commonService.get('Activity/getbyadventuretype/' + adventure_type);
}

//
function getActivitiesByType(activity_type) {
    return commonService.get('Activity/getbytype/' + activity_type);
}

//
function getActivitiesWithstateByModuleAndStudent(module_id, student_id) {
    return commonService.get('Activity/getallbymoduleidwithstate/' + module_id + "/" + student_id);
}

//
function getAllActivitiesByStudentGoupedByModule(student_id) {
    return commonService.get('Activity/getallbystudentidgroupedbymodule/' + student_id);
}

//
function generateReport(student_id) {
    return commonService.getFile('DownloadPdf/printStudentReport/' + student_id);
}

//
export const activityService = {
    getAllAcivities,
    getActivityById,
    getActivitySkill,
    getActivitiesByAdventure,
    getActivitiesByType,
    getActivitiesWithstateByModuleAndStudent,
    getAllActivitiesByStudentGoupedByModule,
    generateReport
};
