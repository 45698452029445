//
export const authActionsType = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',  
    LOGOUT: 'LOGOUT'
};

//
export const alertActionsType = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

//
export const loaderActionsType = {
    PENDING: 'PENDING_LOADER',
    END: 'END_LOADER'
};

//
export const classActionsType = {
    ADD_CLASS: 'ADD_CLASS',
    DELETE_CLASS: 'DELETE_CLASS',
    UPDATE_CLASS: 'UPDATE_CLASS',
    LIST_CLASSES: 'LIST_CLASSES'
};

//
export const studentActionsType = {
    ADD_STUDENT: 'ADD_STUDENT',
    DELETE_STUDENT: 'DELETE_STUDENT',
    UPDATE_STUDENT: 'UPDATE_STUDENT',
    MOVE_STUDENT: 'MOVE_STUDENT',
    LIST_STUDENTS: 'LIST_STUDENTS'
};

//
export const moduleActionsType = {
    LIST_MODULES: 'LIST_MODULES',
    LIST_MODULES_PROGRESS: 'LIST_MODULES_PROGRESS',
    SUCCESS_CERTIFICATE_GENERATION: 'SUCCESS_CERTIFICATE_GENERATION',
}

//
export const activityActionsType = {
    LIST_ACTIVITIES_BY_MODULE: 'LIST_ACTIVITIES_BY_MODULE',
    LIST_ACTIVITIES_REPORTING: 'LIST_ACTIVITIES_REPORTING',
    SUCCESS_NEXT_GAME: 'SUCCESS_NEXT_GAME',
    SUCCESS_REPORT_GENERATION: 'SUCCESS_REPORT_GENERATION',
    ACTIVITIY_DONE: 'ACTIVITIY_DONE'
}