import { classActionsType } from '../actions';

export function classes (state = {}, action) {
  let list;
  switch (action.type) {
    case classActionsType.ADD_CLASS:
      list = state.list;
      list.push(action.oClass)
      return {
        list: list
      };

    case classActionsType.UPDATE_CLASS:
      list = state.list;
      list.map(cl => {
          if (cl.classId === action.oClass.classId) cl.className = action.oClass.className
      });
      return {
        list: list
      };      

    case classActionsType.DELETE_CLASS:
      list = state.list;
      list = list.filter(cl => cl.classId !== action.classId);
      return {
        list: list
      };

    case classActionsType.LIST_CLASSES:
      return {
        list: action.classes
      };

    default:
      return state
  }
}

