import { studentActionsType } from './';
import { studentService } from '../../services';
import { alertActions } from './';

export const studentActions = {
    addStudent,
    deleteStudent,
    updateStudent,
    getStudents,
    moveStudent,
    closeSuccess
};

//
function addStudent(studentName, class_id) {
    return dispatch => {
        studentService.addStudent(studentName, class_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(student) { return { type: studentActionsType.ADD_STUDENT, student } }
}

//
function deleteStudent(student_id) {
    return dispatch => {
        studentService.deleteStudent(student_id)
        .then(
            response => { 
                dispatch(success(student_id));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };   
        function success(studentId) { return { type: studentActionsType.DELETE_STUDENT, studentId } }
}

//
function updateStudent(student_id, student_name) {
    return dispatch => {
        studentService.updateStudent(student_id, student_name)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(student) { return { type: studentActionsType.UPDATE_STUDENT, student } }    
}

//
function moveStudent(student_id, newclass_id) {
    return dispatch => {
        studentService.moveStudent(student_id, newclass_id)
        .then(
            response => { 
                dispatch(success(true));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(moved) { return { type: studentActionsType.MOVE_STUDENT, moved } }    
}

//
function closeSuccess() {
    return dispatch => {
        dispatch ({ type: studentActionsType.MOVE_STUDENT, moved:false })
    } 
}

// List of classes
function getStudents(class_id) {
    return dispatch => {
        studentService.getAllStudents(class_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };
    function success(students) {return { type: studentActionsType.LIST_STUDENTS, students } }
}
