import { commonService } from './';

//
function getAllModules() {
    return commonService.get('Module/getall');
}

//
function getModuleProgressByStudent(student_id) {
    return commonService.get('Module/getbystudentid/' + student_id);
}

//
function generateCertificate(module_id, student_id) {
    return commonService.getFile('DownloadPdf/printModuleCertificate/' + module_id + '/' + student_id);
}

//
export const moduleService = {
    getAllModules,
    getModuleProgressByStudent,
    generateCertificate
};
