import { classActionsType } from './';
import { classService } from '../../services';
import { alertActions } from './';

export const classActions = {
    addClass,
    deleteClass,
    updateClass,
    getClasses
};

//
function addClass(className) {
    return dispatch => {
        classService.addClass(className)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(oClass) { return { type: classActionsType.ADD_CLASS, oClass } }
}

//
function deleteClass(class_id) {
    return dispatch => {
        classService.deleteClass(class_id)
        .then(
            response => { 
                dispatch(success(class_id));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };   
        function success(classId) { return { type: classActionsType.DELETE_CLASS, classId } }
}

//
function updateClass(class_id, class_name) {
    return dispatch => {
        classService.updateClass(class_id, class_name)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };    
    function success(oClass) { return { type: classActionsType.UPDATE_CLASS, oClass } }
}

// List of classes
function getClasses(school_id) {
    return dispatch => {
        classService.getClassesBySchoolId(school_id)
        .then(
            response => { 
                dispatch(success(response));
            },
            error => {
                dispatch(alertActions.error(error));
            });
        };
    function success(classes) {return { type: classActionsType.LIST_CLASSES, classes } }
}