import { alertActionsType } from './';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertActionsType.SUCCESS, message };
}

function error(message) {
    return { type: alertActionsType.ERROR, message };
}

function clear() {
    return { type: alertActionsType.CLEAR };
}