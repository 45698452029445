import React, { Component, Suspense, lazy } from 'react';
import { Router, Route, Switch, withRouter, HashRouter } from "react-router-dom";
import { history, AppStorage } from "../utils";
import ScrollToTop from "../presentation/helpers/ScrollToTop";
import PageSpinner from '../presentation/components/PageSpinner';
import { AdminRoute } from '../routers/AdminRoute';
import { StudentRoute } from '../routers/StudentRoute';
import * as constants from '../constants';

const HomePage = lazy(() => import('../presentation/views/HomePage'));
const AdminPage = lazy(() => import('../presentation/views/AdminPage'));
const StudentPage = lazy(() => import('../presentation/views/StudentPage'));
const StudentClassPage = lazy(() => import('../presentation/views/student/StudentClassPage'));
const StudentPlayerPage = lazy(() => import('../presentation/views/student/StudentPlayerPage'));
const StudentModulePage = lazy(() => import('../presentation/views/student/StudentModulePage'));
const StudentActivityPage = lazy(() => import('../presentation/views/student/StudentActivityPage'));
const StudentProgressPage = lazy(() => import('../presentation/views/student/StudentProgressPage'));
const SuperRescueLevelPage = lazy(() => import('../presentation/views/student/SuperRescueLevelPage'));
const SuperRescueLevel1 = lazy(() => import('../presentation/views/student/SuperRescueLevel1'));
const SuperRescueLevel2 = lazy(() => import('../presentation/views/student/SuperRescueLevel2'));
const NotFoundPage = lazy(() => import('../presentation/views/common/NotFoundPage'));
const NotAuthorized = lazy(() => import('../presentation/views/common/NotAuthorized'));
const FromRfcPage = lazy(() => import('../presentation/views/common/FromRfcPage'));

class AppRouter extends Component {

  render() {

    return (
      <div>
        <HashRouter history={history}>
          <ScrollToTop>
            <Switch>
              <Suspense fallback={<PageSpinner />}>
                
                {AppStorage.get(constants.CURRENT_USER) && AppStorage.get(constants.CURRENT_ROLE) === constants.ADMIN && 
                  <AdminRoute exact path="/" component={withRouter(AdminPage)} />
                }

                {AppStorage.get(constants.CURRENT_USER) && AppStorage.get(constants.CURRENT_ROLE) === constants.STUDENT && 
                  <StudentRoute exact path="/" component={withRouter(StudentPage)} />
                }  

                {!AppStorage.get(constants.CURRENT_USER) &&  
                  <Route exact strict path="/" render={props => <HomePage {...props} />} />
                }              
                
                <Route exact strict path="/home-page" render={props => <HomePage {...props} />} />

                <AdminRoute exact path="/admin-page" component={withRouter(AdminPage)} />
                <StudentRoute exact path="/students-page" component={withRouter(StudentPage)} />
                <StudentRoute exact path="/students-class" component={withRouter(StudentClassPage)} />
                <StudentRoute exact path="/students-player" component={withRouter(StudentPlayerPage)} />
                <StudentRoute exact path="/students-module" component={withRouter(StudentModulePage)} />
                <StudentRoute exact path="/students-activity" component={withRouter(StudentActivityPage)} />
                <StudentRoute exact path="/students-progress" component={withRouter(StudentProgressPage)} />
                <StudentRoute exact path="/super-rescue-level" component={withRouter(SuperRescueLevelPage)} />
                <StudentRoute exact path="/super-rescue-level1" component={withRouter(SuperRescueLevel1)} />
                <StudentRoute exact path="/super-rescue-level2" component={withRouter(SuperRescueLevel2)} />
                <StudentRoute exact path="/404-page" component={withRouter(NotFoundPage)} />
                <StudentRoute exact path="/complete" component={withRouter(FromRfcPage)} />
                <StudentRoute exact path="/complete/:test" component={withRouter(FromRfcPage)} />

                <Route exact strict path='/not-authorized' component={NotAuthorized} />

              </Suspense>
            </Switch>
          </ScrollToTop>
        </HashRouter>

      </div>
    );
  }
}

export default AppRouter;
