import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppStorage } from '../utils';
import * as constants from '../constants';

export const StudentRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        AppStorage.get(constants.TOKEN_USER)
            ? (
                // Check if logged user is student
                AppStorage.get(constants.CURRENT_ROLE) === constants.STUDENT ?
                <Component {...props} />
                : <Redirect to={{ pathname: '/not-authorized', state: { from: props.location } }} />
              )
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)